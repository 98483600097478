import React from "react"
import styled from "styled-components"
import { RED } from "../constants/colors"
import menu from "../constants/menu"

const MenuContainer = styled.div`
  margin: 60px 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 30px 60px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 500;
`

const GroupName = styled.div`
  font-size: 18px;
  color: ${RED};
`

const GroupDesc = styled.div`
  width: 97px;
  font-size: 14px;
  text-align: center;
`

const Item = styled.div``

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 4px;
`

const ItemName = styled.div``

const Separator = styled.div`
  display: block;
  content: "";
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  margin: 11px 8px 0;
`

const Price = styled.div`
  white-space: nowrap;
`

export default function MenuContent() {
  return (
    <MenuContainer>
      {Object.entries(menu).map(([groupName, menuGroup]) => (
        <div key={groupName}>
          <GroupHeader>
            <GroupName>{groupName}</GroupName>
            {groupName === "Pizza" && <GroupDesc>25 cm / 40 cm</GroupDesc>}
          </GroupHeader>
          {menuGroup.map(item => (
            <Item key={item.name}>
              {item.margin && <br />}
              <ItemHeader>
                <ItemName margin={item.margin}>
                  {item.pre && `${item.pre} `}
                  <strong>{item.name}</strong>
                  {item.nameDescJSX && <> {item.nameDescJSX}</>}
                  {item.nameDesc && ` ${item.nameDesc}`}
                </ItemName>
                <Separator />
                <Price>{item.price}</Price>
              </ItemHeader>
            </Item>
          ))}
        </div>
      ))}
    </MenuContainer>
  )
}
